import { ReactElement, ReactNode } from 'react';
import { Col, Row } from 'reactstrap';

import Logo from '../lib/Logo';
import Section from '../lib/Section';

const ErrorLayout = ({ children }: { children: ReactNode }): ReactElement => (
  <Section className="py-0">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={11} md={9} lg={7} xl={6}>
        <Logo />
        {children}
      </Col>
    </Row>
  </Section>
);

export default ErrorLayout;
