import { ReactElement } from 'react';
import classNames from 'classnames';

const Logo = ({ at = 'auth' }: { at?: 'auth' | 'navbar-top' }): ReactElement => (
  <>
    <a
      className={classNames('text-decoration-none', {
        'navbar-brand text-left': at === 'navbar-top',
      })}
      href={process.env.HOMEPAGE_URL}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        className={classNames('d-flex', {
          'align-items-center': at === 'navbar-top',
          'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth',
        })}
      >
        <img className="mr-2" src="/img/logo.svg" alt="logo" />
        <span className="text-sans-serif">zenvest</span>
      </div>
    </a>
    <style jsx>
      {`
        img {
          width: 40px;
        }
      `}
    </style>
  </>
);

export default Logo;
