import { ReactElement } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';

const Error = ({ statusCode = 500 }: { statusCode?: number }): ReactElement => {
  const {
    query: {
      message = 'Whoops, something went wrong.',
      redirect = '/',
      redirectLabel = 'Return to Dashboard',
    },
  } = useRouter();
  return (
    <Card className="text-center h-100">
      <CardBody className="p-5">
        <div className="display-1 text-200 fs-error">{statusCode}</div>
        <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">{message}</p>
        <hr />
        <p>
          If this problem persists,
          <a href="mailto:team@zenvest.com" className="ml-1">
            contact us
          </a>
          .
        </p>
        {/* <Link href="/"> */}
        <Button
          color="primary"
          size="sm"
          onClick={() => window.location.assign(redirect as string)}
        >
          <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1" />
          {redirectLabel}
        </Button>
        {/* </Link> */}
      </CardBody>
    </Card>
  );
};

export default Error;
