import { ReactElement } from 'react';

import ErrorLayout from '../components/error/ErrorLayout';
import Error from '../components/error';

const Error404Page = (): ReactElement => (
  <ErrorLayout>
    <Error statusCode={404} />
  </ErrorLayout>
);

export default Error404Page;
